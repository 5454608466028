import * as LabelPrimitive from '@radix-ui/react-label';
import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '~/utils/misc.ts';
import { Icon } from './icon.tsx';
import { Tooltip, TooltipContent, TooltipTrigger } from './tooltip.tsx';

const labelVariants = cva(
  'font-medium text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
);

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariants> & { tooltip?: string }
>(({ className, tooltip, children, ...props }, ref) => {
  if (tooltip) {
    children = (
      <Tooltip>
        <TooltipTrigger type="button" className="flex items-center gap-1 pb-1">
          {children} <Icon name="help-circle" className="h-4 w-4 text-gray-500" />
        </TooltipTrigger>
        <TooltipContent>
          <p className="max-w-[100vw] text-center md:max-w-[40vw]">{tooltip}</p>
        </TooltipContent>
      </Tooltip>
    );
  }
  return (
    <LabelPrimitive.Root ref={ref} className={cn(labelVariants(), className)} {...props}>
      {children}
    </LabelPrimitive.Root>
  );
});
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
