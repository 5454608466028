import { useLocation } from '@remix-run/react';
import { addDays } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useEvent } from './use-event';
import { useIsAuthenticated } from './user';

declare global {
  interface Window {
    _klOnsite: any[];
    klaviyo: {
      identify: (properties: Record<string, unknown> | undefined) => void;
      track: (event: string, properties: Record<string, unknown> | undefined) => void;
    };
  }
}

export function showKlaviyoForm() {
  window._klOnsite = window._klOnsite || [];
  window._klOnsite.push(['openForm', 'QWbVsR']);
}

export function klaviyoIdentify(properties: Record<string, unknown> | undefined) {
  window.klaviyo?.identify(properties);
}

export function klaviyoTrack(event: string, properties: Record<string, unknown> | undefined) {
  window.klaviyo?.track(event, properties);
}

function useIsLightHouseRunning() {
  return useMemo(
    () => typeof window !== 'undefined' && navigator.userAgent.includes('Lighthouse'),
    [],
  );
}

const formShownStorageKey = 'klaviyo-form-shown';
function useLastShown() {
  const [lastShown, setLastShown] = useState<Date | null>(() => {
    if (typeof window === 'undefined') return null;

    const stored = localStorage.getItem(formShownStorageKey);

    if (!stored) return null;

    const date = new Date(stored);

    if (Number.isNaN(date.getTime())) return null;

    return date;
  });

  function _setLastShown(date: Date) {
    localStorage.setItem(formShownStorageKey, date.toISOString());
    setLastShown(date);
  }

  return { lastShown, setLastShown: _setLastShown };
}

const firstVisitStorageKey = 'klaviyo-first-visit';
function useIsFirstVisit() {
  const location = useLocation();

  const isFirstVisit = useMemo(() => {
    location.pathname;
    if (typeof window === 'undefined') return true;
    return !localStorage.getItem(firstVisitStorageKey);
  }, [location.pathname]);

  useEffect(() => {
    localStorage.setItem(firstVisitStorageKey, 'false');
  }, []);

  return isFirstVisit;
}

type KlaviyoFormProps = {
  hideForDays: number;
  showAfterDelayMs: number;
  showAfterScrolledInPixels: number;
};
export function KlaviyoForm(props: KlaviyoFormProps) {
  const { hideForDays, showAfterDelayMs, showAfterScrolledInPixels } = props;

  const { lastShown, setLastShown } = useLastShown();
  const isAuthenticated = useIsAuthenticated();
  const isLightHouseRunning = useIsLightHouseRunning();
  const isFirstVisit = useIsFirstVisit();

  const shouldShow = useMemo(() => {
    if (isLightHouseRunning) return false;
    if (isAuthenticated) return false;
    if (lastShown && addDays(lastShown, hideForDays).getTime() > Date.now()) return false;
    return true;
  }, [lastShown, isAuthenticated, hideForDays, isLightHouseRunning]);

  const showForm = () => {
    if (typeof window === 'undefined' || !shouldShow) return;
    showKlaviyoForm();
    setLastShown(new Date());
  };

  const showFormEvent = useEvent(showForm);

  useEffect(() => {
    if (!shouldShow || isFirstVisit) return;

    const timeoutId = setTimeout(showFormEvent, showAfterDelayMs);
    return () => {
      return clearTimeout(timeoutId);
    };
  }, [shouldShow, showAfterDelayMs, showFormEvent, isFirstVisit]);

  useEffect(() => {
    if (!shouldShow) return;

    const scrollHandler = () => {
      if (window.scrollY > showAfterScrolledInPixels) {
        showFormEvent();
      }
    };

    window.document.addEventListener('scroll', scrollHandler);
    return () => {
      return window.document.removeEventListener('scroll', scrollHandler);
    };
  }, [shouldShow, showAfterScrolledInPixels, showFormEvent]);

  return null;
}
