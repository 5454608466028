import type {
  HeadersFunction,
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
  SerializeFrom,
} from '@remix-run/node';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  type ShouldRevalidateFunction,
  useLoaderData,
  useRouteError,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import { HydrationBoundary, QueryClientProvider } from '@tanstack/react-query';
import { useState } from 'react';
import { ExternalScript } from 'remix-utils/external-scripts';
import { themeClassName } from './common/constants/theme.tsx';
import { SegmentTracker } from './components/analytics/segment-tracker.client.tsx';
import { AppModeProvider, useAppMode } from './components/dashboard/app-mode-provider.tsx';
import { RootHandleProvider, useRootHandle } from './components/dashboard/root-handle-provider.tsx';
import { GeneralErrorBoundary } from './components/error-boundary.tsx';
import { GlobalProgressIndicator } from './components/global-progress-indicator.tsx';
import { SentryContext } from './components/sentry-context.tsx';
import { href as iconsHref } from './components/ui/icon.tsx';
import { ShowToast, Toaster } from './components/ui/toaster.tsx';
import { TooltipProvider } from './components/ui/tooltip.tsx';
import tailwindStylesheetUrl from './styles/tailwind.css?url';
import { getClientEnv } from './utils/env.server.ts';
import { buildMeta } from './utils/meta.ts';
import { cn } from './utils/misc.ts';
import { useNonce } from './utils/nonce-provider.ts';
import { createQueryClient, useQueryClientState } from './utils/query-client.ts';

export const links: LinksFunction = () => {
  return [
    import.meta.env.PROD && { rel: 'preload', href: tailwindStylesheetUrl, as: 'style' },
    // Preload CSS as a resource to avoid render blocking
    { rel: 'stylesheet', href: tailwindStylesheetUrl, fetchpriority: 'high' },
    // Preload svg sprite as a resource to avoid render blocking
    { rel: 'preload', href: iconsHref, as: 'image' },
    { rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
    {
      rel: 'alternate icon',
      type: 'image/png',
      href: '/favicons/favicon-32x32.png',
    },
    { rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' },
    {
      rel: 'manifest',
      href: '/site.webmanifest',
      crossOrigin: 'use-credentials',
    } as const, // necessary to make typescript happy
    { rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
  ].filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = (args) => {
  return buildMeta(args);
};

export const shouldRevalidate: ShouldRevalidateFunction = ({
  defaultShouldRevalidate,
  currentUrl,
  nextUrl,
  formAction,
  formMethod,
}) => {
  if ((!formMethod || formMethod === 'GET') && currentUrl.pathname === nextUrl.pathname) {
    return false;
  }

  if (currentUrl.pathname !== nextUrl.pathname) {
    return defaultShouldRevalidate;
  }

  if (formAction) {
    return false;
  }

  return defaultShouldRevalidate;
};

export async function loader({ context }: LoaderFunctionArgs) {
  const session = await context.authSession.getOptionalSession();

  return {
    ENV: getClientEnv(context.env),
    session: !!session,
    user: session?.user ?? null,
  };
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
  };
  return headers;
};

function Document({
  children,
  nonce,
  loaderEnv,
}: {
  children: React.ReactNode;
  nonce: string;
  loaderEnv?: SerializeFrom<typeof loader>['ENV'];
}) {
  const handle = useRootHandle();
  const mode = useAppMode();
  return (
    <html lang="en" className={'h-full overflow-x-hidden'}>
      <head>
        <Meta />
        <meta charSet="utf-8" />
        <meta
          suppressHydrationWarning
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1"
        />
        <Links />
      </head>
      <body
        className={cn(
          'flex h-full flex-col bg-background text-foreground',
          mode === 'host' ? themeClassName.host : themeClassName.guest,
          handle?.bodyClass,
        )}
      >
        {children}
        <script
          nonce={nonce}
          // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
          dangerouslySetInnerHTML={{
            __html: `
window.SENTRY=${JSON.stringify(loaderEnv?.SENTRY ?? {})};
window.MODE='${loaderEnv?.MODE ?? ('production' as typeof MODE)}';`,
          }}
        />
        {!!loaderEnv?.KLAVIYO_PUBLIC_KEY && (
          <ExternalScript
            async
            type="text/javascript"
            nonce={nonce}
            src={`https://static.klaviyo.com/onsite/js/${loaderEnv.KLAVIYO_PUBLIC_KEY}/klaviyo.js`}
          />
        )}
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
      </body>
    </html>
  );
}

function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();

  return (
    <RootHandleProvider>
      <AppModeProvider>
        <TooltipProvider>
          <Document nonce={nonce} loaderEnv={data.ENV}>
            <AppQueryClientProvider>
              <GlobalProgressIndicator />
              <Outlet />
              <Toaster />
              <ShowToast />
              {SegmentTracker ? <SegmentTracker /> : null}
              <SentryContext />
            </AppQueryClientProvider>
          </Document>
        </TooltipProvider>
      </AppModeProvider>
    </RootHandleProvider>
  );
}

function AppQueryClientProvider({ children }: { children: React.ReactNode }) {
  const [queryClient] = useState(() => createQueryClient());
  const queryClientState = useQueryClientState();

  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={queryClientState}>{children}</HydrationBoundary>
    </QueryClientProvider>
  );
}

export default withSentry(App);

export function ErrorBoundary() {
  const nonce = useNonce();

  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  return (
    <Document nonce={nonce}>
      <GeneralErrorBoundary />
    </Document>
  );
}
