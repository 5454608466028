import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import * as React from 'react';

import { cn } from '~/utils/misc.ts';

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'fade-in-0 zoom-in-95 data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 animate-in overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-popover-foreground text-sm shadow-md data-[state=closed]:animate-out',
      className,
    )}
    {...props}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };

type ShortTooltipProps = {
  description: string | React.ReactNode;
  children: React.ReactNode;
  asChild?: boolean;
  delayDuration?: number;
  align?: 'start' | 'center' | 'end';
};
export function ShortTooltip({
  description,
  children,
  asChild,
  delayDuration,
  align,
}: ShortTooltipProps) {
  return (
    <Tooltip delayDuration={delayDuration ?? 400}>
      <TooltipTrigger asChild={asChild}>{children}</TooltipTrigger>
      <TooltipContent align={align}>{description}</TooltipContent>
    </Tooltip>
  );
}
