import type { MetaArgs, SerializeFrom } from '@remix-run/node';
import type { loader } from '../root';

type MetaProps = {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
};
export function buildMeta(args: MetaArgs, props?: MetaProps) {
  const rootMatch = args.matches.find((x) => x.id === 'root');
  const appUrl =
    (rootMatch?.data as SerializeFrom<typeof loader>)?.ENV?.APP_URL ?? 'https://joinhostu.com';

  const url = `${appUrl}${args.location.pathname}`;
  const title = props?.title ?? 'hostU | Student Housing';
  const description =
    props?.description ??
    'hostU is a platform that facilitates medium-term shared housing rentals within the university community, connecting students and faculty in need of housing with those looking to rent out their space.';
  const image = props?.image ?? 'https://i.ibb.co/Ms3St5m/host-U-Social-Media.png';

  return [
    { title },
    {
      name: 'description',
      content: description,
    },

    { property: 'og:title', content: title },
    {
      property: 'og:description',
      content: description,
    },
    { property: 'og:url', content: url },
    {
      property: 'og:image',
      content: image,
    },
    { property: 'og:image:width', content: '1200' },
    { property: 'og:image:height', content: '630' },

    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:title', content: title },
    {
      name: 'twitter:description',
      content: description,
    },
    {
      name: 'twitter:image',
      content: image,
    },
  ];
}
