import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '~/utils/misc.ts';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {}

export const inputVariants = cva(
  'flex h-10 w-full px-3 py-2 text-sm file:border-0 file:bg-transparent file:font-medium file:text-sm placeholder:text-muted-foreground placeholder:italic disabled:cursor-not-allowed disabled:opacity-50 aria-[invalid]:border-input-invalid',
  {
    variants: {
      variant: {
        default:
          'rounded-md border border-input bg-background ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
        underline:
          'rounded-t-md border-input border-b bg-transparent ring-offset-background transition-colors focus-visible:border-primary focus-visible:bg-muted focus-visible:outline-none',
        ghost:
          'rounded-md border-0 bg-transparent ring-offset-background transition-colors focus-visible:bg-muted focus-visible:outline-none',
      },
    },
  },
);

export const inputClassName = inputVariants({ variant: 'default' });

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, variant, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(inputVariants({ variant: variant || 'default', className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = 'Input';

export { Input };
