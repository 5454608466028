import { useMatches } from '@remix-run/react';
import type { RouteHandle } from '@remix-run/server-runtime';
import { createContext, useContext, useMemo } from 'react';
import * as R from 'remeda';

export type AppMode = 'host' | 'guest';

const appModeContext = createContext<AppMode>('guest');

export function useAppMode() {
  return useContext(appModeContext);
}

export function AppModeProvider({ children }: { children: React.ReactNode }) {
  const matches = useMatches();
  const mode = useMemo(
    () =>
      R.pipe(
        matches,
        R.filter((x) => !!x && !!x.handle && typeof x.handle === 'object' && 'mode' in x.handle),
        R.map((x) => {
          const handle = x.handle as RouteHandle;

          if (handle.mode === 'host') {
            return 'host';
          }
          if (handle.mode === 'guest') {
            return 'guest';
          }
          return undefined;
        }),
        R.findLast((x) => !!x),
      ),
    [matches],
  );

  return <appModeContext.Provider value={mode ?? 'guest'}>{children}</appModeContext.Provider>;
}
