import type * as React from 'react';
import { DayPicker } from 'react-day-picker';

import { format } from 'date-fns';
import { buttonVariants } from '~/components/ui/button.tsx';
import { cn } from '~/utils/misc.ts';
import { Icon } from './icon.tsx';

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  compact?: boolean;
};

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  compact = false,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        months: cn(
          'flex flex-col sm:flex-row sm:space-y-0',
          compact ? 'space-y-2 sm:space-x-2' : 'space-y-4 sm:space-x-4',
        ),
        month: compact ? 'space-y-2' : 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-sm font-medium',
        nav: 'space-x-1 flex items-center',
        nav_button:
          'h-7 w-7 inline-flex items-center justify-center ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-transparent p-0 opacity-50 hover:opacity-100',
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: cn('w-full border-collapse', compact ? 'space-y-[2px]' : 'space-y-1'),
        head_row: 'flex',
        head_cell: 'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
        row: cn('flex w-full', compact ? 'mt-1' : 'mt-2'),
        cell: 'text-center text-sm p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day: cn(
          buttonVariants({ variant: 'ghost' }),
          'h-9 w-9 p-0 font-normal aria-selected:opacity-100',
        ),
        day_selected:
          'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
        day_today: 'bg-accent text-accent-foreground',
        day_outside: 'day-outside text-muted-foreground',
        day_disabled: 'text-muted-foreground opacity-20',
        day_range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => <Icon name="arrow-left" className="h-4 w-4" />,
        IconRight: ({ ...props }) => <Icon name="arrow-right" className="h-4 w-4" />,
      }}
      formatters={{
        formatCaption(date, options) {
          return format(date, 'MMM, yyyy', { locale: options?.locale });
        },
        formatWeekdayName(date, options) {
          return format(date, 'E', { locale: options?.locale });
        },
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
