import { type HeadersFunction, type LoaderFunctionArgs, json } from '@remix-run/node';
import { Outlet, type ShouldRevalidateFunction } from '@remix-run/react';
import { SocketProvider } from '~/components/socket/socket-provider.tsx';
import { useRouteLoaderDataTyped } from '~/utils/misc.ts';
import { GlobalConfirmDialog } from '#app/components/confirm-dialog.js';

export async function loader({ request, context }: LoaderFunctionArgs) {
  const { toast, headers: toastHeaders } = await context.toast.getToast(request);

  return json(
    {
      toast,
    },
    {
      headers: toastHeaders,
    },
  );
}

export const shouldRevalidate: ShouldRevalidateFunction = ({
  defaultShouldRevalidate,
  currentUrl,
  nextUrl,
  formMethod,
}) => {
  if ((!formMethod || formMethod === 'GET') && currentUrl.pathname === nextUrl.pathname) {
    return false;
  }
  return defaultShouldRevalidate;
};

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  return loaderHeaders;
};

export function useAppLoaderData() {
  return useRouteLoaderDataTyped<typeof loader>('routes/app+/_layout');
}

export default function AppLayout() {
  return (
    <SocketProvider>
      <Outlet />
      <GlobalConfirmDialog />
    </SocketProvider>
  );
}
