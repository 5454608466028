import { type VariantProps, cva } from 'class-variance-authority';

const logoVariants = cva('flex select-none flex-row items-center gap-2 font-black', {
  variants: {
    size: {
      default: 'text-2xl',
      lg: 'text-3xl',
      xl: 'text-4xl',
      '2xl': 'text-5xl',
      '3xl': 'text-6xl',
      '4xl': 'text-7xl',
      '5xl': 'text-8xl',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

const uVariants = cva('flex items-center justify-center rounded-[50%] bg-hostU-blue-gradient', {
  variants: {
    size: {
      default: 'h-9 w-9',
      lg: 'h-12 w-12',
      xl: 'h-12 w-12',
      '2xl': 'h-16 w-16',
      '3xl': 'h-20 w-20',
      '4xl': 'h-24 w-24',
      '5xl': 'h-28 w-28',
      '6xl': 'h-32 w-32',
      '7xl': 'h-36 w-36',
      '8xl': 'h-40 w-40',
    },
    color: {
      light: 'text-white',
    },
  },
  defaultVariants: {
    color: 'light',
    size: 'default',
  },
});

export function Logo(variants: VariantProps<typeof logoVariants> & VariantProps<typeof uVariants>) {
  return (
    <div className={logoVariants(variants)}>
      <span>host</span>
      <span className={uVariants(variants)}>U</span>
    </div>
  );
}
