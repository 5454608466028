import { useInputControl } from '@conform-to/react';
import type React from 'react';
import { useId } from 'react';
import { Checkbox, type CheckboxProps } from '~/components/ui/checkbox.tsx';
import { Input, type InputProps } from '~/components/ui/input.tsx';
import { Label } from '~/components/ui/label.tsx';
import { Textarea } from '~/components/ui/textarea.tsx';

export type ListOfErrors = Array<string | null | undefined> | null | undefined;

export function ErrorList({
  id,
  errors,
}: {
  errors?: ListOfErrors;
  id?: string;
}) {
  const errorsToRender = errors?.filter(Boolean);
  if (!errorsToRender?.length) return null;
  return (
    <ul id={id} className="flex flex-col gap-1">
      {errorsToRender.map((e) => (
        <li key={e} className="text-[10px] text-foreground-danger">
          {e}
        </li>
      ))}
    </ul>
  );
}

export type BaseFieldProps = Omit<FieldWrapperProps, 'id' | 'children'>;
type FieldWrapperProps = {
  id: string;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement> & {
    tooltip?: string;
  };
  children: React.ReactNode;
  errors?: ListOfErrors;
  className?: string;
};
export function FieldWrapper({ id, labelProps, children, errors, className }: FieldWrapperProps) {
  const errorId = errors?.length ? `${id}-error` : undefined;
  return (
    <div className={className}>
      {!!labelProps && <Label htmlFor={id} {...labelProps} />}
      {children}
      <div className={'min-h-[20px] px-4 pt-1'}>
        {errorId && <ErrorList id={errorId} errors={errors} />}
      </div>
    </div>
  );
}

export function Field({
  inputProps,
  children,
  ...wrapperProps
}: BaseFieldProps & {
  inputProps: InputProps;
  children?: React.ReactNode;
}) {
  const fallbackId = useId();
  const id = inputProps.id ?? fallbackId;
  const errorId = wrapperProps.errors?.length ? `${id}-error` : undefined;
  return (
    <FieldWrapper id={id} {...wrapperProps}>
      <Input
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...inputProps}
        key={id}
      />
      {children}
    </FieldWrapper>
  );
}

export function TextareaField({
  textareaProps,
  ...wrapperProps
}: BaseFieldProps & {
  textareaProps: React.InputHTMLAttributes<HTMLTextAreaElement>;
}) {
  const fallbackId = useId();
  const id = textareaProps.id ?? textareaProps.name ?? fallbackId;
  const errorId = wrapperProps.errors?.length ? `${id}-error` : undefined;
  return (
    <FieldWrapper id={id} {...wrapperProps}>
      <Textarea
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...textareaProps}
      />
    </FieldWrapper>
  );
}

export function CheckboxField({
  labelProps,
  buttonProps,
  errors,
  className,
}: {
  labelProps: JSX.IntrinsicElements['label'];
  buttonProps: CheckboxProps & {
    name: string;
    form: string;
    value?: string;
  };
  errors?: ListOfErrors;
  className?: string;
}) {
  const { key, defaultChecked, ...checkboxProps } = buttonProps;

  const fallbackId = useId();

  const checkedValue = buttonProps.value ?? 'on';

  const input = useInputControl({
    key,
    name: buttonProps.name,
    formId: buttonProps.form,
    initialValue: defaultChecked ? checkedValue : undefined,
  });

  const id = buttonProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  return (
    <div className={className}>
      <div className="flex items-center gap-2">
        <Checkbox
          id={id}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          value={input.value}
          {...checkboxProps}
          {...buttonProps}
          onCheckedChange={(state) => {
            input.change(state.valueOf() ? checkedValue : '');
            buttonProps.onCheckedChange?.(state);
          }}
          onFocus={(event) => {
            input.focus();
            buttonProps.onFocus?.(event);
          }}
          onBlur={(event) => {
            input.blur();
            buttonProps.onBlur?.(event);
          }}
          type="button"
        />
        <label
          htmlFor={id}
          {...labelProps}
          className="select-none self-center text-body-xs text-muted-foreground"
        />
      </div>
      <div className="px-4 pt-1 pb-3">
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  );
}
