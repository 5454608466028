import { Slot } from '@radix-ui/react-slot';
import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '~/utils/misc.ts';

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-lg font-medium text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        simple: 'bg-transparent text-primary',
        black: 'bg-black text-white hover:bg-black/80',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        'destructive-outline':
          'border border-destructive bg-background text-destructive hover:bg-destructive/20',
        outline: 'border border-primary bg-background text-primary hover:bg-accent',
        'black-outline':
          'border border-black bg-background text-black hover:border-black/80 hover:bg-gray-100/20 hover:opacity-80',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        'ghost-outline':
          'border border-gray-200 border-secondary text-gray-400 hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        gradient: 'bg-hostU-blue-gradient text-primary-foreground hover:opacity-80',
        gray: 'bg-gray-background text-gray-500 hover:opacity-80',
        green: 'bg-[#83CB45] text-white hover:opacity-80',
        'blue-gray': 'bg-blue-gray text-white hover:opacity-80',
      },
      size: {
        default: 'h-10 px-4 py-2',
        wide: 'px-24 py-5',
        sm: 'h-9 rounded-lg px-3',
        lg: 'h-11 rounded-lg px-8',
        pill: 'px-12 py-3 leading-3',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
