import { useEffect } from 'react';
import { Toaster as SonnerToaster, toast as showToast } from 'sonner';
import { useAppLoaderData } from '~/routes/app+/_layout.tsx';

export function Toaster() {
  return (
    <>
      <SonnerToaster
        richColors
        closeButton
        position="bottom-right"
        className="!bottom-[max(12px,env(safe-area-inset-bottom))] !right-[max(12px,env(safe-area-inset-bottom))]"
      />
    </>
  );
}

export function ShowToast() {
  const data = useAppLoaderData();
  const toast = data?.toast;

  useEffect(() => {
    if (!toast) return;
    const { id, type, message } = toast;
    setTimeout(() => {
      showToast[type](message, { id });
    }, 0);
  }, [toast]);

  return null;
}

export { showToast };
