import { Link, Outlet, useMatches } from '@remix-run/react';
import { useMemo } from 'react';
import { AppButton } from '~/components/app-button.tsx';
import { useAppMode } from '~/components/dashboard/app-mode-provider.tsx';
import { ErrorActions, ErrorHeader, GeneralErrorBoundary } from '~/components/error-boundary.tsx';
import { Logo } from '~/components/logo.tsx';
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from '~/components/ui/navigation-menu.tsx';
import { cn } from '~/utils/misc.ts';
import { KlaviyoForm } from '#app/utils/klaviyo.js';

export type LandingHandle = {
  mainClass?: string;
};

function isLandingHandle(handle?: unknown): handle is LandingHandle {
  return !!handle && typeof handle === 'object' && 'mainClass' in handle;
}

function useLandingHandles() {
  const matches = useMatches();
  const handle = useMemo(
    () => matches.find((x) => isLandingHandle(x.handle))?.handle as LandingHandle,
    [matches],
  );

  return handle ?? ({} satisfies LandingHandle);
}

function HostOrGuestButton() {
  const isForHost = useAppMode() === 'host';

  const to = isForHost ? '/' : '/host';
  const text = isForHost ? 'find a place' : 'list your place';

  return (
    <NavigationMenuLink
      asChild
      className={cn(navigationMenuTriggerStyle(), 'font-extrabold text-md')}
    >
      <Link to={to} viewTransition prefetch="intent">
        {text}
      </Link>
    </NavigationMenuLink>
  );
}

function LandingNavigationMenu() {
  return (
    <NavigationMenu>
      <NavigationMenuList className="gap-2">
        <NavigationMenuItem>
          <HostOrGuestButton />
        </NavigationMenuItem>
        <NavigationMenuItem>
          <AppButton className="rounded-full font-extrabold text-md" />
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
}

function LandingFooter() {
  return (
    <footer className="flex items-center justify-center border-border border-t px-4 py-12 md:px-8 md:py-16">
      <nav className="flex w-full max-w-[1440px] flex-col justify-between gap-8 md:flex-row md:gap-0">
        <div className="flex flex-col gap-2">
          <Link to="/" prefetch="intent">
            <Logo />
          </Link>
          <p className="text-gray-500">©2025 All Rights Reserved.</p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="font-bold text-lg">Join</p>
          <Link className="text-gray-500" to="/host" prefetch="intent">
            Become a host
          </Link>
          <Link className="text-gray-500" to="/" prefetch="intent">
            Become a guest
          </Link>
        </div>
        <div className="flex flex-col gap-2">
          <p className="font-bold text-lg">Legal</p>
          <Link className="text-gray-500" to="/terms-of-service" prefetch="intent">
            Terms Of Service
          </Link>
          <Link className="text-gray-500" to="/privacy-policy" prefetch="intent">
            Privacy Policy
          </Link>
        </div>
        <div className="flex flex-col gap-2">
          <p className="font-bold text-lg">Contact</p>
          <a className="text-gray-500" href="mailto:hello@joinhostu.com">
            Get in touch
          </a>
        </div>
      </nav>
    </footer>
  );
}

function Layout({ children }: { children: React.ReactNode }) {
  const { mainClass } = useLandingHandles();
  return (
    <>
      <header className="fixed top-0 right-0 left-0 z-50 flex h-[var(--landing-header-height)] items-center justify-center border-border border-b bg-white px-4 md:px-8">
        <div className="flex w-full max-w-[1440px] flex-row items-center justify-between">
          <Link to="/" prefetch="intent">
            <Logo />
          </Link>
          <LandingNavigationMenu />
        </div>
      </header>
      <div className="min-h-[var(--landing-header-height)]" />
      <main className={cn('flex-1', mainClass)}>{children}</main>
      <LandingFooter />
      <KlaviyoForm hideForDays={1} showAfterDelayMs={5000} showAfterScrolledInPixels={400} />
    </>
  );
}

export default function LandingLayout() {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export function ErrorBoundary() {
  return (
    <Layout>
      <GeneralErrorBoundary
        defaultHandler={() => (
          <>
            <ErrorHeader title="Oops!" message="Something went wrong..." />
            <ErrorActions
              left={{
                to: '/',
                text: 'Back to landing',
                variant: 'ghost-outline',
              }}
              right={<AppButton />}
            />
          </>
        )}
      />
    </Layout>
  );
}
