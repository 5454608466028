import { useMatches } from '@remix-run/react';
import { type DehydratedState, QueryCache, QueryClient } from '@tanstack/react-query';
import merge from 'deepmerge';
import { useMemo } from 'react';

export function createQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
        refetchOnMount: true,
        staleTime: 10 * 1000,
        retry: 2,
      },
    },
    queryCache: new QueryCache({}),
  });
}

export function useQueryClientState(): DehydratedState | undefined {
  const matches = useMatches();

  return useMemo(() => {
    const queryClient = matches
      .map((match) =>
        match.data &&
        typeof match.data === 'object' &&
        'queryClient' in match.data &&
        typeof match.data.queryClient === 'object'
          ? (match.data.queryClient as DehydratedState)
          : null,
      )
      .filter(Boolean);

    return queryClient.length
      ? (queryClient.reduce(
          (accumulator, currentValue) => merge(accumulator, currentValue),
          {},
        ) as DehydratedState)
      : undefined;
  }, [matches]);
}
