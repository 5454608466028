import * as React from 'react';
import { useSpinDelay } from 'spin-delay';
import { cn } from '~/utils/misc.ts';
import { Button, type ButtonProps } from './button.tsx';
import { Icon } from './icon.tsx';
import { Tooltip, TooltipContent, TooltipTrigger } from './tooltip.tsx';

export const StatusButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    status: 'pending' | 'success' | 'error' | 'idle';
    message?: string | null;
    spinDelay?: Parameters<typeof useSpinDelay>[1];
    noDivWrapper?: boolean;
  }
>(({ message, status, className, children, spinDelay, noDivWrapper, ...props }, ref) => {
  const delayedPending = useSpinDelay(status === 'pending', {
    delay: 400,
    minDuration: 300,
    ...spinDelay,
  });
  const companion = {
    pending: delayedPending ? (
      <div className="inline-flex h-6 w-6 items-center justify-center">
        <Icon name="update" className="animate-spin" />
      </div>
    ) : null,
    success: (
      <div className="inline-flex h-6 w-6 items-center justify-center">
        <Icon name="check" />
      </div>
    ),
    error: (
      <div className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-destructive">
        <Icon name="cross-1" className="text-destructive-foreground" />
      </div>
    ),
    idle: null,
  }[status];

  return (
    <Button ref={ref} className={cn('flex justify-center gap-4', className)} {...props}>
      {!noDivWrapper && <div>{children}</div>}
      {!!noDivWrapper && children}
      {message ? (
        <Tooltip>
          <TooltipTrigger>{companion}</TooltipTrigger>
          <TooltipContent>{message}</TooltipContent>
        </Tooltip>
      ) : (
        companion
      )}
    </Button>
  );
});
StatusButton.displayName = 'Button';
